import SneznoJezero from '../../../images/gallery/2022/snezno jezero.jpg';
import CvetneLampe from '../../../images/gallery/2022/cvetne lampe.jpg';
import DzezuSumi from '../../../images/gallery/2022/dzez u sumi.jpg';
import Fruska from '../../../images/gallery/2022/fruska.jpg';
import Irvasi from '../../../images/gallery/2022/irvasi.jpg';
import Kafenisanje from '../../../images/gallery/2022/kafenisanje.jpg';
import Priroda from '../../../images/gallery/2022/priroda.jpg';
import Radionica from '../../../images/gallery/2022/radionica.jpg';
import Lomaca from '../../../images/gallery/2022/lomaca.jpg';
import Maca from '../../../images/gallery/2022/maca.jpg';
import SatorUnutra from '../../../images/gallery/2022/sator unutra.jpg';
import SmeskoRodjendan from '../../../images/gallery/2022/smesko rodjendan.jpg';
import SatorSpolja from '../../../images/gallery/2022/sator spolja.jpg';
import OneManBandShow from '../../../images/gallery/2022/one man band show.jpg';
import Solja from '../../../images/gallery/2022/solja.jpg';
import Solje from '../../../images/gallery/2022/solje.jpg';
import Vrata from '../../../images/gallery/2022/vrata.jpg';
import Changeover from '../../../images/gallery/2022/changeover.jpg';
import HalloweenJazz from '../../../images/gallery/2022/halloween jazz.jpg';
import Vatra from '../../../images/gallery/2022/vatra.jpg';
import Zar from '../../../images/gallery/2022/zar.jpg';
import Noc from '../../../images/gallery/2022/noc.jpg';
import ValentineJazz from '../../../images/gallery/2022/valentine jazz.jpg';

import Cvece from '../../../images/gallery/2022/cvece.jpg';
import Cvece1 from '../../../images/gallery/2022/cvece1.jpg';
import Motor from '../../../images/gallery/2022/motor.jpg';
import Motor1 from '../../../images/gallery/2022/motor1.jpg';
import Porcelan from '../../../images/gallery/2022/porcelan.jpg';
import Porcelan1 from '../../../images/gallery/2022/porcelan1.jpg';
import GlinenaBajka from '../../../images/gallery/2022/glinena bajka.jpg';
import GlinenaBajka1 from '../../../images/gallery/2022/glinena bajka1.jpg';

/*import  from '../../../images/gallery/2022/.jpg';*/

function Galerija22() {

    return (

        <div className=''>

            <div className='row'>

                <div className='column'>
                    <img src={SneznoJezero} alt='' />
                    <img src={Lomaca} alt='' />
                    <img src={GlinenaBajka} alt='' />
                    <img src={Porcelan1} alt='' />
                    <img src={SatorUnutra} alt='' />
                    <img src={Lomaca} alt='' />
                    <img src={HalloweenJazz} alt='' />
                    <img src={Noc} alt='' />
                </div>

                <div className='column'>
                    <img src={Zar} alt='' />
                    <img src={DzezuSumi} alt='' />
                    <img src={SmeskoRodjendan} alt='' />
                    <img src={Priroda} alt='' />
                    <img src={Motor} alt='' />
                    <img src={Solja} alt='' />
                    <img src={Vatra} alt='' />
                    <img src={CvetneLampe} alt='' />
                </div>

                <div className='column'>
                    <img src={Cvece} alt='' />
                    <img src={Fruska} alt='' />
                    <img src={Kafenisanje} alt='' />
                    <img src={Radionica} alt='' />
                    <img src={GlinenaBajka1} alt='' />
                    <img src={OneManBandShow} alt='' />
                    <img src={Vrata} alt='' />
                    <img src={Motor1} alt='' />
                </div>

                <div className='column'>
                    <img src={Cvece1} alt='' />
                    <img src={Porcelan} alt='' />
                    <img src={SatorSpolja} alt='' />
                    <img src={Maca} alt='' />
                    <img src={Irvasi} alt='' />
                    <img src={Solje} alt='' />
                    <img src={ValentineJazz} alt='' />
                    <img src={Changeover} alt='' />
                </div>

            </div>

        </div>
  
    );

}
  
export default Galerija22;
