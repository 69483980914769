import Firma from '../images/smesko/gorskismesko-text.png';

function Header() {

    return (

        <div className='header'>

            <img src={Firma} className='responsive' alt='' />

        </div>

    );

}

export default Header;
