import Priroda from '../../../images/gallery/2017/priroda.jpg';
import Gnezdo from '../../../images/gallery/2017/gnezdo.jpg';
import Suncanje from '../../../images/gallery/2017/mesto za suncanje.jpg';
import Dobrodosli from '../../../images/gallery/2017/dobrodosli.jpg';
import Buba from '../../../images/gallery/2017/buba.jpg';
import Rostilj from '../../../images/gallery/2017/rostilj.jpg';
import Putokazi from '../../../images/gallery/2017/putokazi.jpg';
import Veverica from '../../../images/gallery/2017/veverica.jpg';
import Izvor from '../../../images/gallery/2017/izvor.jpg';
import Kampovanje from '../../../images/gallery/2017/kampovanje.jpg';
import Podrum from '../../../images/gallery/2017/podrum.jpg';

import Cuvarkuca from '../../../images/gallery/2017/cuvarkuca.jpg';
import Cuvarkuca1 from '../../../images/gallery/2017/cuvarkuca1.jpg';
import Bar from '../../../images/gallery/2017/bar.jpg';
import Bar1 from '../../../images/gallery/2017/bar1.jpg';
import Otvaranje from '../../../images/gallery/2017/otvaranje.jpg';
import Otvaranje1 from '../../../images/gallery/2017/otvaranje1.jpg';
import SedenjeJezero from '../../../images/gallery/2017/sedenje jezero.jpg';
import SedenjeJezero1 from '../../../images/gallery/2017/sedenje jezero1.jpg';
import Palete from '../../../images/gallery/2017/palete.jpg';
import Palete1 from '../../../images/gallery/2017/palete1.jpg';
import Stolice1 from '../../../images/gallery/2017/stolice1.jpg';
import Stolice3 from '../../../images/gallery/2017/stolice3.jpg';
import Sedenje from '../../../images/gallery/2017/sedenje.jpg';
import Sedenje1 from '../../../images/gallery/2017/sedenje5.jpg';
import Sedenje2 from '../../../images/gallery/2017/sedenje6.jpg';
import Jama from '../../../images/gallery/2017/jama.jpg';
import Jama1 from '../../../images/gallery/2017/jama1.jpg';
import Jama2 from '../../../images/gallery/2017/jama2.jpg';
import Jezero from '../../../images/gallery/2017/jezero.jpg';
import Jezero1 from '../../../images/gallery/2017/jezero1.jpg';
import Jezero2 from '../../../images/gallery/2017/jezero2.jpg';


/*import  from '../../../images/gallery/2017/.jpg';*/

function Galerija17() {

    return (

        <div className=''>

            <div className='row'>

                <div className='column'>
                    <img src={Jezero} alt='' />
                    <img src={Jezero1} alt='' />
                    <img src={Jezero2} alt='' />
                    <img src={SedenjeJezero} alt='' />
                    <img src={SedenjeJezero1} alt='' />
                    <img src={Cuvarkuca1} alt='' />
                    <img src={Izvor} alt='' />
                    <img src={Putokazi} alt='' />
                </div>

                <div className='column'>
                    <img src={Jama} alt='' />
                    <img src={Jama1} alt='' />
                    <img src={Jama2} alt='' />
                    <img src={Priroda} alt='' />
                    <img src={Cuvarkuca} alt='' />
                    <img src={Rostilj} alt='' />
                    <img src={Sedenje1} alt='' />
                    <img src={Podrum} alt='' />
                </div>

                <div className='column'>
                    <img src={Dobrodosli} alt='' />
                    <img src={Bar} alt='' />
                    <img src={Bar1} alt='' />
                    <img src={Kampovanje} alt='' />
                    <img src={Buba} alt='' />
                    <img src={Otvaranje} alt='' />
                    <img src={Stolice3} alt='' />
                    <img src={Suncanje} alt='' />
                </div>

                <div className='column'>
                    <img src={Gnezdo} alt='' />
                    <img src={Palete} alt='' />
                    <img src={Palete1} alt='' />
                    <img src={Sedenje} alt='' />
                    <img src={Veverica} alt='' />
                    <img src={Otvaranje1} alt='' />
                    <img src={Stolice1} alt='' />
                    <img src={Sedenje2} alt='' />
                </div>

            </div>

        </div>
  
    );

}
  
export default Galerija17;
