import OSmesku from '../../images/smesko/osmesku.png';
import GdeSmo from '../../images/smesko/gdesmo.png';
import Dogadjaji from '../../images/smesko/dogadjaji.png';

function ONama() {

    return (

        <div className='wrapperLight'>

            <div className=''>

                <center>
                    <img src={OSmesku} className='responsive' alt='O Gorskom Smešku'/>
                </center>

                {/*<hr className='horizon' />*/}

                Na Popovici, na desetak minuta od grada, nalazi se Udruženje prijatelja prirode "Gorski Smeško" gde možete da se odmorite 
                nakon šetnje po Fruškoj Gori ili samo da se sklonite od gradske vreve.<br /><br />
                Udruženje je smešteno u prelepom šumskom ambijentu i uređeno tako da slavi prirodu i opuštenost. U skladu sa misijom očuvanja 
                prirode, stolice i stolovi su napravljeni od prirodnih i recikliranih materijala kao što su drvene palete, stare automobilske 
                gume, stara burad i slično.
                <br /><br />
                Jedan stari auto poslužio je kao zgodan dvosed, a na nekoliko mesta su okačene pletene ležaljke za odmaranje i leškarenje. 
                Ambijent je upotpunjen starim kombijem koji je postao pravo umetničko delo budući da je maštovito oslikan. U ovom originalnom 
                ambijentu se povremeno organizuju projekcije filmova, džez koncerti, kao i drugi događaji.
                <br /><br />
                Naravno, dobrodošli su i vaši kućni ljubimci.
                <br />

            </div>

            <hr className='hr' />

            <div className=''>

                <center>
                    <img src={GdeSmo} className='responsive' alt='Gde se nalazimo'/>
                </center>

                {/*<hr className='horizon' />*/}

                Od okretnice autobusa na Popovici se krene putem ka Orlovom bojištu i na nekih 50 metara sa leve strane ćete pronaći Udruženje 
                prijatelja prirode "Gorski Smeško".<br /><br />

                <center>

                <iframe
                    width='100%'
                    title='Gorski Smeško map'
                    style={{ border: 0 }}
                    allowfullscreen=''
                    loading='lazy'
                    referrerpolicy='no-referrer-when-downgrade'
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d789.1283462090591!2d19.822780985136703!3d45.18439698125053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b05862c52d3f3%3A0x4b714cfea48484c!2sCaffe%20Gorski%20sme%C5%A1ko!5e0!3m2!1ssr!2srs!4v1661516072710!5m2!1ssr!2srs'></iframe>

                </center>

            </div>

            <hr className='hr' />

            <div className=''>

                <center>
                    <img src={Dogadjaji} className='responsive' alt='Dogadjaji i aktivnosti'/>
                </center>

                {/*<hr className='horizon' />*/}

                Za najnovije informacije o dogadjajima i aktivnostima molimo vas da posetite naše&nbsp;
                <a href='https://www.facebook.com/gorskismesko/' title='Gorski Smesko Facebook' rel='noreferrer' target='_blank'>Facebook</a> i &nbsp;
                <a href='https://www.instagram.com/gorski_smesko/' title='Gorski Smesko Instagram' rel='noreferrer' target='_blank'>Instagram </a>
                stranice.

            </div>

        </div>

    );

}

export default ONama;
