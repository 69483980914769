import React from 'react';
import { Outlet, Link } from 'react-router-dom';

import Galerija17 from '../../../images/gallery/2017.png';
import Galerija18 from '../../../images/gallery/2018.png';
import Galerija19 from '../../../images/gallery/2019.png';
import Galerija20 from '../../../images/gallery/2020.png';
import Galerija21 from '../../../images/gallery/2021.png';
import Galerija22 from '../../../images/gallery/2022.png';
import Galerija23 from '../../../images/gallery/2023.png';
import Galerija24 from '../../../images/gallery/2024.png';

/*import '../../../css/gallery.css';*/

const GLayout = () => {

    return (

        <>

            <nav className='gallery'>
                
                <div className=''>

                    <Link to='/galerija/galerija17'>
                        <img src={Galerija17} width='30%' alt='Gorski Smesko 2017' className='imgwidth' />
                    </Link>
                    <Link to='/galerija/galerija18'>
                        <img src={Galerija18} width='30%' alt='Gorski Smesko 2018' className='imgwidth' />
                    </Link>
                    <Link to='/galerija/galerija19'>
                        <img src={Galerija19} width='30%' alt='Gorski Smesko 2019' className='imgwidth' />
                    </Link>
                    <Link to='/galerija/galerija20'>
                        <img src={Galerija20} width='30%' alt='Gorski Smesko 2020' className='imgwidth' />
                    </Link>
                    <Link to='/galerija/galerija21'>
                        <img src={Galerija21} width='30%' alt='Gorski Smesko 2021' className='imgwidth' />
                    </Link>
                    <Link to='/galerija/galerija22'>
                        <img src={Galerija22} width='30%' alt='Gorski Smesko 2022' className='imgwidth' />
                    </Link>
                    <Link to='/galerija/galerija23'>
                        <img src={Galerija23} width='30%' alt='Gorski Smesko 2023' className='imgwidth' />
                    </Link>
                    <Link to='/galerija/galerija24'>
                        <img src={Galerija24} width='30%' alt='Gorski Smesko 2024' className='imgwidth' />
                    </Link>

                </div>

            </nav>

            <hr className='horizon' />

            <Outlet />
        
        </>

    )
  
};

export default GLayout;
