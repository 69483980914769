import RadniDan from '../../../images/gallery/2019/radni dan.jpg';
import SuncaniDan from '../../../images/gallery/2019/suncani dan.jpg';
import SnezniKuca from '../../../images/gallery/2019/snezni kuca.jpg';
import Autobus from '../../../images/gallery/2019/autobus.jpg';
import BubaGrill from '../../../images/gallery/2019/buba grill.jpg';
import Esoteric from '../../../images/gallery/2019/esoteric.jpg';
import TamnostVatre from '../../../images/gallery/2019/tamnost vatre.jpg';
import Pasulj from '../../../images/gallery/2019/pasulj.jpg';
import Festival from '../../../images/gallery/2019/festival.jpg';
import Muffin from '../../../images/gallery/2019/muffin.jpg';
import LepotePrirode from '../../../images/gallery/2019/lepote prirode.jpg';
import Sah from '../../../images/gallery/2019/sah.jpg';
import Jesen from '../../../images/gallery/2019/jesen.jpg';
import FestivalSume from '../../../images/gallery/2019/festival sume.jpg';
import SumskiKuca from '../../../images/gallery/2019/sumski kuca.jpg';
import Kombi from '../../../images/gallery/2019/kombi.jpg';
import Duga from '../../../images/gallery/2019/duga.jpg';
import OcistimoSumu from '../../../images/gallery/2019/ocistimo sumu.jpg';
import DnevniBioskop2 from '../../../images/gallery/2019/dnevni bioskop2.jpg';

import Sneg from '../../../images/gallery/2019/sneg.jpg';
import Sneg1 from '../../../images/gallery/2019/sneg1.jpg';
import Sneg5 from '../../../images/gallery/2019/sneg5.jpg';
import Grejanje from '../../../images/gallery/2019/grejanje.jpg';
import Grejanje1 from '../../../images/gallery/2019/grejanje1.jpg';
import ImaliKoga from '../../../images/gallery/2019/ima li koga.jpg';
import ImaliKoga3 from '../../../images/gallery/2019/ima li koga3.jpg';
import Odmaraliste from '../../../images/gallery/2019/odmaraliste.jpg';
import Odmaraliste1 from '../../../images/gallery/2019/odmaraliste1.jpg';
import BaruNedodjiji from '../../../images/gallery/2019/bar u nedodjiji.jpg';
import BaruNedodjiji1 from '../../../images/gallery/2019/bar u nedodjiji1.jpg';

/*import  from '../../../images/gallery/2019/.jpg';*/

function Gallery19() {

    return (

        <div className=''>

            <div className='row'>

                <div className='column'>
                    <img src={Sneg} alt='' />
                    <img src={Sneg5} alt='' />
                    <img src={Grejanje} alt='' />
                    <img src={OcistimoSumu} alt='' />
                    <img src={Jesen} alt='' />
                    <img src={Grejanje1} alt='' />
                    <img src={ImaliKoga3} alt='' />
                </div>
                
                <div className='column'>
                    <img src={Sneg5} alt='' />
                    <img src={RadniDan} alt='' />
                    <img src={BubaGrill} alt='' />
                    <img src={Muffin} alt='' />
                    <img src={Duga} alt='' />
                    <img src={SuncaniDan} alt='' />
                    <img src={BaruNedodjiji} alt='' />
                    <img src={Kombi} alt='' />
                </div>

                <div className='column'>
                    <img src={Sneg1} alt='' />
                    <img src={Autobus} alt='' />
                    <img src={FestivalSume} alt='' />
                    <img src={Pasulj} alt='' />
                    <img src={Odmaraliste1} alt='' />
                    <img src={DnevniBioskop2} alt='' />
                    <img src={LepotePrirode} alt='' />
                    <img src={SumskiKuca} alt='' />
                </div>

                <div className='column'>
                    <img src={SnezniKuca} alt='' />
                    <img src={Odmaraliste} alt='' />
                    <img src={BaruNedodjiji1} alt='' />
                    <img src={TamnostVatre} alt='' />
                    <img src={Festival} alt='' />
                    <img src={Esoteric} alt='' />
                    <img src={Sah} alt='' />
                    <img src={ImaliKoga} alt='' />
                </div>

            </div>

        </div>
  
    );

}
  
export default Gallery19;
