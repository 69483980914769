function GMain() {

    return (

        <div className='gallery'>

            Dobro došli u galeriju Gorskog Smeška. <br/><br/>
            Molimo vas da izaberete godinu vezanu za galeriju koju želite da pogledate.
            <br/><br/>

        </div>
  
    );
  
}
  
export default GMain;
