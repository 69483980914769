import { Outlet, Link } from 'react-router-dom';

const Layout = () => {

    return (

        <>
        
            <nav className=''>

                <div className='menu' id='menu_section'>

                    <div className='left'>

                        <Link style={{ textDecoration: 'none' }} to='/'>Dobrodošli</Link>&nbsp;-&nbsp;
                        <Link style={{ textDecoration: 'none' }} to='galerija'>Foto galerija</Link>&nbsp;-&nbsp;
                        <Link style={{ textDecoration: 'none' }} to='oNama'>O nama</Link>

                    </div>

                    <div><br /></div>

                    <div className='right'>
                        
                        <Link style={{ textDecoration: 'none' }} to='/'>Welcome</Link>&nbsp;-&nbsp;
                        <Link style={{ textDecoration: 'none' }} to='gallery'>Photo gallery</Link>&nbsp;-&nbsp;
                        <Link style={{ textDecoration: 'none' }} to='aboutUs'>About Us</Link>

                    </div>

                </div>

                <Outlet />

            </nav>

        </>

    )
};

export default Layout;
