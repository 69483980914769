/*import SouloClock from '../../../images/gallery/2024/souloclock.jpg';*/
import JogaSetnja from '../../../images/gallery/2024/jogasetnja.jpg';
import SuperGroove from '../../../images/gallery/2024/supergroove.jpg';
import AstroSetnja from '../../../images/gallery/2024/astrosetnja.jpg';
import Rodjendan2024 from '../../../images/gallery/2024/rodjendan2024.jpg';

import Noc from '../../../images/gallery/2024/noc.jpg';
import Noc1 from '../../../images/gallery/2024/noc1.jpg';
import DanZena from '../../../images/gallery/2024/8mart.jpg';
import DanZena1 from '../../../images/gallery/2024/8mart1.jpg';
import Sneg from '../../../images/gallery/2024/sneg.jpg';
import Sneg1 from '../../../images/gallery/2024/sneg1.jpg';
import Sneg2 from '../../../images/gallery/2024/sneg2.jpg';
import Sneg3 from '../../../images/gallery/2024/sneg3.jpg';

/*import Construction from '../../../images/misc/under construction.png';*/

/*import  from '../../../images/gallery/2024/.jpg';*/

function Galerija24() {

    return (

        <div className='middle'>
            
            {/*<img src={Construction} className='construction' alt='Under Construction' />*/}

            <div className='row'>

                <div className='column'>
                    <img src={Sneg} alt='' />
                    <img src={DanZena} alt='' />
                    <img src={Noc} alt='' />
                    {/*<img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />*/}
                </div>

                <div className='column'>
                    <img src={Sneg1} alt='' />
                    <img src={DanZena1} alt='' />
                    <img src={Noc1} alt='' />
                    {/*<img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />*/}
                </div>

                <div className='column'>
                    <img src={Sneg2} alt='' />
                    <img src={SuperGroove} alt='' />
                    <img src={AstroSetnja} alt='' />
                    {/*<img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />*/}
                </div>

                <div className='column'>
                    <img src={Sneg3} alt='' />
                    <img src={JogaSetnja} alt='' />
                    <img src={Rodjendan2024} alt='' />
                    {/*<img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />
                    <img src={} alt='' />*/}
                </div>

            </div>

        </div>

    );

}

export default Galerija24;
