import Facebook from '../images/logo/facebook-fav.ico';
import Instagram from '../images/logo/instagram-fav.ico';

function Footer() {

    return (

        <div className='footer'>

            <div className='copyright'>

                Copyright © 2024 Gorski Smeško<br />
                All Rights Reserved

            </div>

            <div className='social'>

                <a href='https://www.facebook.com/gorskismesko/' title='Gorski Smeško Facebook' rel='noreferrer' target='_blank'>
                    <img src={Facebook} alt=''/>
                </a>

                &nbsp;&nbsp;&nbsp;&nbsp;

                <a href='https://www.instagram.com/gorski_smesko/' title='Gorski Smeško Instagram' rel='noreferrer' target='_blank'>
                    <img src={Instagram} alt=''/>
                </a>

            </div>

        </div>

    );

}

export default Footer;
