import Basta from '../../../images/gallery/2018/basta.jpg';
import Lotus from '../../../images/gallery/2018/lotus.jpg';
import Jedro from '../../../images/gallery/2018/jedro.jpg';
import Jezero from '../../../images/gallery/2018/jezero.jpg';
import Fruska from '../../../images/gallery/2018/fruska.jpg';
import Zatisje from '../../../images/gallery/2018/zatisje.jpg';
import Okokanebu from '../../../images/gallery/2018/oko ka nebu.jpg';
import Klackalica from '../../../images/gallery/2018/klackalica.jpg';
import RadnoVreme from '../../../images/gallery/2018/radno vreme.jpg';
import SnezneBobice from '../../../images/gallery/2018/snezne bobice.jpg';

import Sneg from '../../../images/gallery/2018/sneg.jpg';
import Sneg1 from '../../../images/gallery/2018/sneg1.jpg';
import Slama from '../../../images/gallery/2018/slama.jpg';
import Slama1 from '../../../images/gallery/2018/slama1.jpg';
import Priroda from '../../../images/gallery/2018/priroda.jpg';
import Priroda1 from '../../../images/gallery/2018/priroda1.jpg';
import Dvosedi from '../../../images/gallery/2018/dvosedi.jpg';
import Dvosedi1 from '../../../images/gallery/2018/dvosedi1.jpg';
import Gusenica from '../../../images/gallery/2018/gusenica.jpg';
import Gusenica1 from '../../../images/gallery/2018/gusenica1.jpg';
import Prosirenje from '../../../images/gallery/2018/prosirenje.jpg';
import Prosirenje1 from '../../../images/gallery/2018/prosirenje1.jpg';
import Prosirenje2 from '../../../images/gallery/2018/prosirenje2.jpg';
import KombiFarbanje from '../../../images/gallery/2018/kombi farbanje.jpg';
import KombiFarbanje1 from '../../../images/gallery/2018/kombi farbanje 1.jpg';
import ProsirenjeIznutra from '../../../images/gallery/2018/prosirenje iznutra.jpg';
import ProsirenjeIznutra1 from '../../../images/gallery/2018/prosirenje iznutra 1.jpg';
import ProsirenjeIznutra2 from '../../../images/gallery/2018/prosirenje iznutra 2.jpg';
import Kocije from '../../../images/gallery/2018/kocije.jpg';
import Kocije1 from '../../../images/gallery/2018/kocije1.jpg';
import Kocije2 from '../../../images/gallery/2018/kocije2.jpg';
import Kocije3 from '../../../images/gallery/2018/kocije3.jpg';

/*import  from '../../../images/gallery/2018/.jpg';*/

function Gallery18() {

    return (

        <div className=''>

            <div className='row'>

                <div className='column'>
                    <img src={RadnoVreme} alt='' />
                    <img src={Sneg} alt='' />
                    <img src={Sneg1} alt='' />
                    <img src={Jezero} alt='' />
                    <img src={Lotus} alt='' />
                    <img src={KombiFarbanje} alt='' />
                    <img src={KombiFarbanje1} alt='' />
                    <img src={Kocije3} alt='' />
                </div>

                <div className='column'>
                    <img src={Kocije} alt='' />
                    <img src={Kocije1} alt='' />
                    <img src={Kocije2} alt='' />
                    <img src={Dvosedi} alt='' />
                    <img src={Dvosedi1} alt='' />
                    <img src={Slama} alt='' />
                    <img src={Slama1} alt='' />
                    <img src={Basta} alt='' />
                </div>

                <div className='column'>
                    <img src={Prosirenje} alt='' />
                    <img src={Prosirenje1} alt='' />
                    <img src={Prosirenje2} alt='' />
                    <img src={ProsirenjeIznutra} alt='' />
                    <img src={ProsirenjeIznutra1} alt='' />
                    <img src={Klackalica} alt='' />
                    <img src={Priroda} alt='' />
                    <img src={Priroda1} alt='' />
                </div>

                <div className='column'>
                    <img src={Okokanebu} alt='' />
                    <img src={Fruska} alt='' />
                    <img src={Gusenica} alt='' />
                    <img src={Gusenica1} alt='' />
                    <img src={Jedro} alt='' />
                    <img src={ProsirenjeIznutra2} alt='' />
                    <img src={Zatisje} alt='' />
                    <img src={SnezneBobice} alt='' />
                </div>

        </div>

        </div>
  
    );

}
  
export default Gallery18;
