import Logo from '../images/logo/logo-krug.png';

function Main() {

    return (

        <div className=''>

            <div className='main flex'>
            
                <div className=''>

                    <br />

                    U sred srca Fruške Gore,<br />
                    Gde i jelen stiže teško,<br />
                    Već vekova sto i više,<br />
                    Živi mali Gorski Smeško.<br />

                    <br />

                    U šupljini starog debla,<br />
                    Iza čvora on stanuje,<br />
                    Na leđima stare sove,<br />
                    Noću goru proputuje.<br />

                    <br />

                    Kad čvor takav ugledate,<br />
                    Nek' vam nikad nije teško,<br />
                    Zakucajte, kad tu bude,<br />
                    Javiće se Gorski Smeško.

                    <br /><br />

                </div>

                <div className='middle'>

                    <img src={Logo} className='logo' width='75%' alt=''/>
                    <hr className='hrM' />

                </div>

                <div className=''>

                    <br />

                    In the heart of Fruška Gora,<br />
                    Where deer wearyingly appears,<br />
                    For centuries and even more,<br />
                    Little Gorski Smeško lives.<br />

                    <br />

                    In an old tree's hollow,<br />
                    Behind a knot he lives,<br />
                    On an old owls back,<br />
                    Nightly the highland he oversees.<br />

                    <br />

                    When you see a knot like that,<br />
                    May it never be hard,<br />
                    Knock, and when he's there,<br />
                    Gorski Smeško will appear.

                    <br /><br />

                </div>

            </div>

            <div className='middle'>

                Autor: Pavle Janković - Šole
                
            </div>

        </div>

    );

}

export default Main;
