import React from 'react';
import { Outlet, Link } from 'react-router-dom';

import Gallery17 from '../../../images/gallery/2017.png';
import Gallery18 from '../../../images/gallery/2018.png';
import Gallery19 from '../../../images/gallery/2019.png';
import Gallery20 from '../../../images/gallery/2020.png';
import Gallery21 from '../../../images/gallery/2021.png';
import Gallery22 from '../../../images/gallery/2022.png';
import Gallery23 from '../../../images/gallery/2023.png';
import Gallery24 from '../../../images/gallery/2024.png';

/*import '../../../css/gallery.css';*/

const GLayout = () => {

    return (

        <>

            <nav className='gallery'>
                
                <div className=''>

                    <Link to='/gallery/gallery17'>
                        <img src={Gallery17} width='30%' alt='Gorski Smesko 2017' className='imgwidth' />
                    </Link>
                    <Link to='/gallery/gallery18'>
                        <img src={Gallery18} width='30%' alt='Gorski Smesko 2018' className='imgwidth' />
                    </Link>
                    <Link to='/gallery/gallery19'>
                        <img src={Gallery19} width='30%' alt='Gorski Smesko 2019' className='imgwidth' />
                    </Link>
                    <Link to='/gallery/gallery20'>
                        <img src={Gallery20} width='30%' alt='Gorski Smesko 2020' className='imgwidth' />
                    </Link>
                    <Link to='/gallery/gallery21'>
                        <img src={Gallery21} width='30%' alt='Gorski Smesko 2021' className='imgwidth' />
                    </Link>
                    <Link to='/gallery/gallery22'>
                        <img src={Gallery22} width='30%' alt='Gorski Smesko 2022' className='imgwidth' />
                    </Link>
                    <Link to='/gallery/gallery23'>
                        <img src={Gallery23} width='30%' alt='Gorski Smesko 2023' className='imgwidth' />
                    </Link>
                    <Link to='/gallery/gallery24'>
                        <img src={Gallery24} width='30%' alt='Gorski Smesko 2024' className='imgwidth' />
                    </Link>

                </div>

            </nav>

            <hr className='horizon' />

            <Outlet />
        
        </>

    )
  
};

export default GLayout;
