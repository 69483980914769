import AboutSmesko from '../../images/smesko/aboutus.png';
import Location from '../../images/smesko/location.png';
import Events from '../../images/smesko/events.png';

function AboutUs() {

    return (

        <div className='wrapperLight'>

            <div className=''>

                <center>
                    <img src={AboutSmesko} className='responsive' alt='About Smesko'/>
                </center>

                {/*<hr className='horizon' />*/}

                On Popovica, ten minutes from the city, there is the Association of Friends of Nature "Gorski Smeško" where you can rest
                after a walk on Fruška Gora or just to get away from the hustle and bustle of the city.<br /><br />
                The association is located in a beautiful forest setting and decorated to celebrate nature and relaxation. In keeping with the conservation mission
                of nature, chairs and tables are made from natural and recycled materials such as wooden pallets, old car ones
                tires, old barrels and the like.
                <br /><br />
                An old car served as a convenient two-seater, and in several places, wicker deckchairs were hung for resting and lounging.
                The ambiance is completed with an old van that has become a real work of art as it has been imaginatively painted. In this original
                film screenings, jazz concerts, and other events are occasionally organized in the ambience.
                <br /><br />
                Your pets are also welcome.
                <br />

            </div>

            <hr className='hr' />

            <div className=''>

                <center>
                    <img src={Location} className='responsive' alt='Location'/>
                </center>

                {/*<hr className='horizon' />*/}

                From the bus turnpike on Popovica, take the road towards Orlovo Bojšte and after about 50 meters on the left you will find the Association
                friend of nature "Gorski Smeško".<br /><br />

                <center>

                <iframe
                    width='100%'
                    title='Gorski Smeško map'
                    style={{ border: 0 }}
                    allowfullscreen=''
                    loading='lazy'
                    referrerpolicy='no-referrer-when-downgrade'
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d789.1283462090591!2d19.822780985136703!3d45.18439698125053!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b05862c52d3f3%3A0x4b714cfea48484c!2sCaffe%20Gorski%20sme%C5%A1ko!5e0!3m2!1ssr!2srs!4v1661516072710!5m2!1ssr!2srs'></iframe>

                </center>

            </div>

            <hr className='hr' />

            <div className=''>

                <center>
                    <img src={Events} className='responsive' alt='Events'/>
                </center>

                {/*<hr className='horizon' />*/}

                For the latest information on events and activities, please visit our&nbsp;
                <a href='https://www.facebook.com/gorskismesko/' title='Gorski Smesko Facebook' rel='noreferrer' target='_blank'>Facebook</a> and&nbsp;
                <a href='https://www.instagram.com/gorski_smesko/' title='Gorski Smesko Instagram' rel='noreferrer' target='_blank'>Instagram </a>
                pages.

            </div>

        </div>

    );

}

export default AboutUs;
