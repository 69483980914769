import Lomaca from '../../../images/gallery/2023/badnjevece-lomaca.jpg';
import LomacaGori from '../../../images/gallery/2023/badnjevece-lomacagori.jpg';
import SnezniKotao from '../../../images/gallery/2023/snezni kotao.jpg';
import FilmoreBirds from '../../../images/gallery/2023/filmore birds.jpg';
import Buba from '../../../images/gallery/2023/buba.jpg';
import DzezUSumi from '../../../images/gallery/2023/dzez u sumi.jpg';
import GoreTikve from '../../../images/gallery/2023/gore tikve.jpg';
import GoriTikva from '../../../images/gallery/2023/gori tikva.jpg';
import GlinenaBajka from '../../../images/gallery/2023/glinena bajka.jpg';
import Ognjiste from '../../../images/gallery/2023/ognjiste.jpg';
import NoveCase from '../../../images/gallery/2023/nove case.jpg';
import Bundeve from '../../../images/gallery/2023/bundeve.jpg';
import Dvosedi from '../../../images/gallery/2023/dvosedi.jpg';
import Instrumenti from '../../../images/gallery/2023/instrumenti.jpg';
import GajenjeBiljaka from '../../../images/gallery/2023/gajenje biljaka.jpg';
import ReconectedNature from '../../../images/gallery/2023/reconected nature.jpg';
import DrvoJezero from '../../../images/gallery/2023/drvo jezero.jpg';
import ValentineDayJazz from '../../../images/gallery/2023/valentine day jazz.jpg';
import BambusLezaljka from '../../../images/gallery/2023/bambus lezaljka.jpg';
import Zvezda from '../../../images/gallery/2023/zvezda.jpg';
import OtvorenaVrata from '../../../images/gallery/2023/otvorena vrata.jpg';
import ZimskaVatra from '../../../images/gallery/2023/zimska vatra.jpg';
import SnezniSator from '../../../images/gallery/2023/snezni sator.jpg';
import SnezniPrah from '../../../images/gallery/2023/snezni prah.jpg';
import Outriders from '../../../images/gallery/2023/outriders.jpg';
import SnezniIrvasi from '../../../images/gallery/2023/snezni irvasi.jpg';
import Rostilj from '../../../images/gallery/2023/rostilj.jpg';
import Love from '../../../images/gallery/2023/love.jpg';

/*import  from '../../../images/gallery/2023/.jpg';*/

function Gallery23() {

    return (

        <div className=''>

            <div className='row'>

                <div className='column'>
                    <img src={SnezniKotao} alt='' />
                    <img src={GoreTikve} alt='' />
                    <img src={DzezUSumi} alt='' />
                    <img src={ReconectedNature} alt='' />
                    <img src={NoveCase} alt='' />
                    <img src={Outriders} alt='' />
                    <img src={Buba} alt='' />
                    {/*<img src={} alt='' />*/}
                </div>

                <div className='column'>
                    <img src={SnezniIrvasi} alt='' />
                    <img src={Ognjiste} alt='' />
                    <img src={Bundeve} alt='' />
                    <img src={Instrumenti} alt='' />
                    <img src={DrvoJezero} alt='' />
                    <img src={SnezniPrah} alt='' />
                    <img src={Rostilj} alt='' />
                    {/*<img src={} alt='' />*/}
                </div>

                <div className='column'>
                    <img src={ZimskaVatra} alt='' />
                    <img src={Zvezda} alt='' />
                    <img src={Lomaca} alt='' />
                    <img src={FilmoreBirds} alt='' />
                    <img src={Dvosedi} alt='' />
                    <img src={ValentineDayJazz} alt='' />
                    <img src={GajenjeBiljaka} alt='' />
                    {/*<img src={} alt='' />*/}
                </div>

                <div className='column'>
                    <img src={SnezniSator} alt='' />
                    <img src={LomacaGori} alt='' />
                    <img src={GoriTikva} alt='' />
                    <img src={GlinenaBajka} alt='' />
                    <img src={OtvorenaVrata} alt='' />
                    <img src={BambusLezaljka} alt='' />
                    <img src={Love} alt='' />
                    {/*<img src={} alt='' />*/}
                </div>

            </div>
            
        </div>

    );

}

export default Gallery23;
