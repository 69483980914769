function GMain() {

    return (

        <div className='gallery'>

            Welcome to the gallery of Gorski Smeško. <br/><br/>
            Please choose the corresponding year you wish to view the gallery.
            <br/><br/>

        </div>
  
    );
  
}
  
export default GMain;
