import Header from './pages/header';
import Menu from './pages/menu';
import Footer from './pages/footer';

function App() {

  return (

    <div className='wrapper center'>

      <Header />
      <Menu />
      <Footer />

    </div>

  );

}

export default App;
