function NoPage() {

    return (

        <div>

            404

        </div>

    );

}

export default NoPage;
