import Prolece from '../../../images/gallery/2021/prolece.jpg';
import Pogled from '../../../images/gallery/2021/pogled.jpg';
import UnutarnjiProstor from '../../../images/gallery/2021/unutarnji prostor.jpg';
import Sedenje from '../../../images/gallery/2021/sedenje.jpg';
import Uskrs from '../../../images/gallery/2021/uskrs.jpg';
import SpoljasnjiBar from '../../../images/gallery/2021/spoljasnji bar.jpg';
import HajkaSmece from '../../../images/gallery/2021/hajka smece.jpg';
import DzezKisa from '../../../images/gallery/2021/dzez kisa.jpg';
import Haloween from '../../../images/gallery/2021/haloween.jpg';
import Dzez from '../../../images/gallery/2021/dzez.jpg';
import Inukshuk from '../../../images/gallery/2021/inukshuk.jpg';
import Zalazak from '../../../images/gallery/2021/zalazak.jpg';
import Skoljka from '../../../images/gallery/2021/skoljka.jpg';
import ZimskeCarolije from '../../../images/gallery/2021/zimske carolije.jpg';
import Odmor from '../../../images/gallery/2021/odmor.jpg';
import Leto2021 from '../../../images/gallery/2021/leto2021.jpg';
import Izlozba from '../../../images/gallery/2021/izlozba.jpg';
import Istorija from '../../../images/gallery/2021/istorija.jpg';
import Istorija1 from '../../../images/gallery/2021/istorija1.jpg';
import Zurkautisini from '../../../images/gallery/2021/zurka u tisini.jpg';
import Ugao from '../../../images/gallery/2021/ugao.jpg';
import Bus from '../../../images/gallery/2021/bus.jpg';
import JazzuSumi from '../../../images/gallery/2021/jazz u sumi.jpg';
import Srce from '../../../images/gallery/2021/srce.jpg';
import Dobrodosli from '../../../images/gallery/2021/dobrodosli.jpg';
import Sunce from '../../../images/gallery/2021/sunce.jpg';

import Bar from '../../../images/gallery/2021/bar.jpg';
import Sneg from '../../../images/gallery/2021/sneg.jpg';
import Sneg1 from '../../../images/gallery/2021/sneg1.jpg';
import Lisce from '../../../images/gallery/2021/lisce.jpg';
import Lisce1 from '../../../images/gallery/2021/lisce1.jpg';
import Lisce2 from '../../../images/gallery/2021/lisce2.jpg';

/*import  from '../../../images/gallery/2021/.jpg';*/

function Gallery21() {

    return (

        <div className=''>

            <div className='row'>

                <div className='column'>
                    <img src={Sneg} alt='' />
                    <img src={Ugao} alt='' />
                    <img src={Prolece} alt='' />
                    <img src={HajkaSmece} alt='' />
                    <img src={Dzez} alt='' />
                    <img src={Zalazak} alt='' />
                    <img src={Leto2021} alt='' />
                    <img src={JazzuSumi} alt='' />
                </div>

                <div className='column'>
                    <img src={Bar} alt='' />
                    <img src={Sneg1} alt='' />
                    <img src={Pogled} alt='' />
                    <img src={Lisce} alt='' />
                    <img src={SpoljasnjiBar} alt='' />
                    <img src={Inukshuk} alt='' />
                    <img src={Haloween} alt='' />
                    <img src={Srce} alt='' />
                </div>

                <div className='column'>
                    <img src={Skoljka} alt='' />
                    <img src={Sedenje} alt='' />
                    <img src={Uskrs} alt='' />
                    <img src={Lisce1} alt='' />
                    <img src={Odmor} alt='' />
                    <img src={Istorija} alt='' />
                    <img src={Zurkautisini} alt='' />
                    <img src={Dobrodosli} alt='' />
                </div>

                <div className='column'>
                    <img src={ZimskeCarolije} alt='' />
                    <img src={UnutarnjiProstor} alt='' />
                    <img src={Bus} alt='' />
                    <img src={Lisce2} alt='' />
                    <img src={Izlozba} alt='' />
                    <img src={DzezKisa} alt='' />
                    <img src={Istorija1} alt='' />
                    <img src={Sunce} alt='' />
                </div>

            </div>

        </div>
  
    );

}
  
export default Gallery21;
