import UlaznaVrata from '../../../images/gallery/2020/ulazna vrata.jpg';
import Putanja from '../../../images/gallery/2020/putanja.jpg';
import OkrugliSto from '../../../images/gallery/2020/okrugli sto.jpg';
import StoSrce from '../../../images/gallery/2020/sto srce.jpg';
import RostiljKobasice from '../../../images/gallery/2020/rostilj kobasice.jpg';
import NoviStolovi from '../../../images/gallery/2020/novi stolovi.jpg';
import Oblaci from '../../../images/gallery/2020/oblaci.jpg';
import Cigle from '../../../images/gallery/2020/cigle.jpg';
import Cvece from '../../../images/gallery/2020/cvece.jpg';
import Lezaljka from '../../../images/gallery/2020/lezaljka.jpg';
import Bar from '../../../images/gallery/2020/bar.jpg';
import Bure from '../../../images/gallery/2020/bure.jpg';
import Odmor from '../../../images/gallery/2020/odmor.jpg';
import Pivo from '../../../images/gallery/2020/pivo.jpg';
import Bioskop from '../../../images/gallery/2020/bioskop.jpg';
import DzezuSumi from '../../../images/gallery/2020/dzez u sumi.jpg';
import BubaSaksija from '../../../images/gallery/2020/buba saksija.jpg';
import Bube from '../../../images/gallery/2020/bube.jpg';
import BureStolice from '../../../images/gallery/2020/bure stolice.jpg';
import Uskrs from '../../../images/gallery/2020/uskrs.jpg';
import BeanBags from '../../../images/gallery/2020/beanbags.jpg';
import JesenjeLisce from '../../../images/gallery/2020/jesenje lisce.jpg';
import Vatra from '../../../images/gallery/2020/vatra.jpg';
import JelkaSisarke from '../../../images/gallery/2020/jelka sisarke.jpg';

import Suncobran from '../../../images/gallery/2020/suncobran.jpg';
import Suncobran1 from '../../../images/gallery/2020/suncobran1.jpg';
import Igraliste from '../../../images/gallery/2020/igraliste.jpg';
import Igraliste1 from '../../../images/gallery/2020/igraliste1.jpg';
import ZatvorenoCigle from '../../../images/gallery/2020/zatvoreni deo - ciglani pod.jpg';
import ZatvorenoCigle1 from '../../../images/gallery/2020/zatvoreni deo - ciglani pod 1.jpg';
import ZatvorenoCigle2 from '../../../images/gallery/2020/zatvoreni deo - ciglani pod 2.jpg';
import ZatvorenoCigle3 from '../../../images/gallery/2020/zatvoreni deo - ciglani pod 3.jpg';

/*import  from '../../../images/gallery/2020/.jpg';*/

function Galerija20() {

    return (

        <div className=''>

            <div className='row'>

                <div className='column'>
                    <img src={UlaznaVrata} alt='' />
                    <img src={Putanja} alt='' />
                    <img src={Cigle} alt='' />
                    <img src={Suncobran} alt='' />
                    <img src={BeanBags} alt='' />
                    <img src={ZatvorenoCigle2} alt='' />
                    <img src={Igraliste1} alt='' />
                    <img src={JelkaSisarke} alt='' />
                    
                </div>
                
                <div className='column'>
                    <img src={Lezaljka} alt='' />
                    <img src={OkrugliSto} alt='' />
                    <img src={DzezuSumi} alt='' />
                    <img src={NoviStolovi} alt='' />
                    <img src={Bure} alt='' />
                    <img src={Odmor} alt='' />
                    <img src={Oblaci} alt='' />
                    <img src={ZatvorenoCigle1} alt='' />
                </div>

                <div className='column'>
                    <img src={StoSrce} alt='' />
                    <img src={RostiljKobasice} alt='' />
                    <img src={Cvece} alt='' />
                    <img src={Bar} alt='' />
                    <img src={Pivo} alt='' />
                    <img src={ZatvorenoCigle} alt='' />
                    <img src={Uskrs} alt='' />
                    <img src={BureStolice} alt='' />
                </div>

                <div className='column'>
                    <img src={BubaSaksija} alt='' />
                    <img src={Igraliste} alt='' />
                    <img src={Suncobran1} alt='' />
                    <img src={ZatvorenoCigle3} alt='' />
                    <img src={Bioskop} alt='' />
                    <img src={Vatra} alt='' />
                    <img src={JesenjeLisce} alt='' />
                    <img src={Bube} alt='' />
                </div>

            </div>

        </div>
  
    );

}
  
export default Galerija20;
